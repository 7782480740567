import {
  Box,
  Button,
  Container,
  Divider,
  Grow,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import _ from "lodash";
import React, { useCallback, useEffect } from "react";
import { Body1, H6 } from "./tipografia";
import RadioForm from "./radio-form";
import Icone from "./icone";
import { blue } from "@mui/material/colors";
import { ToastErro } from "../utils/utils";

/**
 * QuestionForm component
 * Um componente de questionario que recebe uma lista com
 * os questionarios e em cada questionario tem uma lista com as
 * perguntas envolvidas, com seu tipo, texto e opcoes (caso exista).
 * Cada pergunta pode ter também um campo para a proxima pergunta
 * e em alguns casos resposta já anexadas no campo resposta.
 */

const inflateResponses = (questions, viewResponses) => {
  const responses = {};
  questions.forEach((question) => {
    if (!responses.hasOwnProperty(question.id)) {
      responses[question.id] = {};
    }
    // Inclua as perguntas
    _.forEach(question.perguntas, (pergunta) => {
      if (viewResponses) {
        if (pergunta.resposta) {
          responses[question.id][pergunta.id] = pergunta.resposta;
        }
      } else {
        responses[question.id][pergunta.id] = null;
      }
    });
  });
  return responses;
};

function QuestionForm({ questions, onFinishQuestions, viewResponses }) {
  const [currentQuestion, setCurrentQuestion] = React.useState(0);
  const [responses, setResponses] = React.useState(
    inflateResponses(questions, viewResponses)
  );
  const nextQuestionary =
    currentQuestion < questions.length ? questions[currentQuestion] : null;

  useEffect(() => {
    if (!nextQuestionary) {
      if (viewResponses) {
        ToastErro("Nâo existe outro questionário a ser exibido");
        setCurrentQuestion((val) => (val === -1 ? 0 : val - 1));
        return;
      }
      onFinishQuestions(responses);
    }
  }, [nextQuestionary, viewResponses, onFinishQuestions, responses]);

  return (
    <Container>
      {nextQuestionary && (
        <Questions
          key={currentQuestion}
          question={questions[currentQuestion]}
          setResponses={setResponses}
          setCurrentQuestion={setCurrentQuestion}
          responses={responses}
          viewResponses={viewResponses}
          currentQuestion={currentQuestion}
        />
      )}
    </Container>
  );
}
//
const getAsk = (asks, index) => {
  return asks.find((ask) => ask.id === index);
};
//
const Questions = ({
  question,
  setResponses,
  setCurrentQuestion,
  currentQuestion,
  responses,
  viewResponses,
}) => {
  const [nextAsk, setNextAsk] = React.useState({
    next: question.perguntas[0].id,
    prev: -1,
  });
  const perguntas = question.perguntas;

  // Aciona o botao para retornar a pergunta anterior
  const onGoBackAsk = useCallback(() => {
    let askPrev = -1;
    if (nextAsk.prev > -1) {
      _.forEach(perguntas, (pergunta, index) => {
        if (pergunta.proxima_pergunta === nextAsk.prev) {
          askPrev = pergunta.id;
          return;
        }
        _.forEach(pergunta.opcoes, (opcao) => {
          if (opcao.proxima_pergunta === nextAsk.prev) {
            askPrev = pergunta.id;
            return;
          }
        });
      });
      responses[question.id][nextAsk.prev] = null;
    }

    setNextAsk((val) => {
      return {
        next: val.prev,
        prev: askPrev,
      };
    });
  }, [setNextAsk, question, responses, perguntas, nextAsk]);
  //
  const onNextQuestion = useCallback(() => {
    setCurrentQuestion((val) => val + 1);
  }, [setCurrentQuestion]);
  //
  const onGoBackQuestion = useCallback(() => {
    setCurrentQuestion((val) => val - 1);
  }, [setCurrentQuestion]);

  const ask = getAsk(perguntas, nextAsk.next);
  if (!ask) {
    if (window.confirm(`FINALIZAR QUESTIONÁRIO "${question.titulo}"?`)) {
      setCurrentQuestion((val) => val + 1);
    } else {
      onGoBackAsk();
    }
    return null;
  }
  //
  const asksWithResponse = [];
  _.keys(responses[question.id]).forEach((key) => {
    if (responses[question.id][key]) {
      const askResp = getAsk(question.perguntas, parseInt(key));
      asksWithResponse.push(askResp);
    }
  });

  return (
    <Paper sx={{ my: 1, p: 1, minHeight: "50vh" }}>
      <Container maxWidth="md">
        <H6>{question.titulo}</H6>
        <Divider sx={{ my: 2 }} />
        {!viewResponses && (
          <Grow in key={ask?.id} unmountOnExit>
            <Box>
              <AskForm
                key={ask.id}
                ask={ask}
                questionId={question.id}
                typeAsk={ask?.tipo}
                observation={ask?.observacao}
                setNextAsk={setNextAsk}
                setResponses={setResponses}
                onGoBackAsk={onGoBackAsk}
                disabledReturn={nextAsk.prev === -1}
              />
            </Box>
          </Grow>
        )}
        {asksWithResponse.length > 0 && (
          <Paper
            elevation={4}
            sx={{ p: 1, my: 2, border: `2px solid ${blue[700]}` }}
          >
            <Body1 textAlign="center">PERGUNTAS RESPONDIDAS</Body1>
            {asksWithResponse.map((ask, idx) => (
              <Grow key={`${ask.id}_response`} in unmountOnExit>
                <Box
                  sx={{
                    borderBottom:
                      idx < asksWithResponse.length - 1
                        ? "1px solid #ccc"
                        : "none",
                  }}
                >
                  <AskForm
                    ask={ask}
                    questionId={question.id}
                    typeAsk={ask?.tipo}
                    observation={ask?.observacao}
                    setNextAsk={setNextAsk}
                    setResponses={setResponses}
                    response={responses[question.id][ask.id]}
                  />
                </Box>
              </Grow>
            ))}
          </Paper>
        )}
        {viewResponses && (
          <Stack direction="row" justifyContent="space-between">
            <Button
              startIcon={<Icone icone="ArrowBack" />}
              variant="outlined"
              onClick={onGoBackQuestion}
              disabled={currentQuestion < 1}
            >
              QUESTIONÁRIO ANTERIOR
            </Button>
            <Button
              startIcon={<Icone icone="ArrowForward" />}
              variant="outlined"
              onClick={onNextQuestion}
            >
              PRÓXIMO QUESTIONÁRIO
            </Button>
          </Stack>
        )}
      </Container>
    </Paper>
  );
};
//

const AskForm = ({
  ask,
  typeAsk,
  setNextAsk,
  setResponses,
  questionId,
  response,
  onGoBackAsk,
  disabledReturn,
  observation,
}) => {
  const [value, setValue] = React.useState("");
  const askId = ask.id;
  const options = ask?.opcoes;
  // Aciona o botao de salvar data
  const onSaveDate = useCallback(
    (e) => {
      if (!/^\d{4}-\d{2}-\d{2}$/.test(value)) {
        return null;
      }

      setResponses((val) => {
        return {
          ...val,
          [questionId]: {
            ...val[questionId],
            [ask.id]: value,
          },
        };
      });

      setNextAsk((val) => {
        return {
          next: ask.proxima_pergunta,
          prev: val.next,
        };
      });
    },
    [value, setResponses, questionId, ask, setNextAsk]
  );
  // Aciona o botao de salvar campo formulario texto
  const onSaveText = useCallback(
    (e) => {
      setResponses((val) => {
        return {
          ...val,
          [questionId]: {
            ...val[questionId],
            [ask.id]: value,
          },
        };
      });

      setNextAsk((val) => {
        return {
          next: ask.proxima_pergunta,
          prev: val.next,
        };
      });
    },
    [ask, questionId, setResponses, setNextAsk, value]
  );
  //
  const onSaveOption = useCallback(() => {
    const option = getOptionSelected(options, value);
    setResponses((val) => {
      return {
        ...val,
        [questionId]: {
          ...val[questionId],
          [askId]: option.id,
        },
      };
    });

    if (option?.proxima_pergunta) {
      setNextAsk((val) => {
        return {
          next: option.proxima_pergunta,
          prev: val.next,
        };
      });
    } else if (ask?.proxima_pergunta) {
      setNextAsk((val) => {
        return {
          next: ask.proxima_pergunta,
          prev: val.next,
        };
      });
    } else {
      setNextAsk((val) => {
        return {
          next: null,
          prev: val.next,
        };
      });
    }
  }, [value, options, askId, ask, setResponses, questionId, setNextAsk]);
  //
  let fnExecNext;
  if (typeAsk === "DATA") {
    fnExecNext = onSaveDate;
  } else if (typeAsk === "MEU") {
    fnExecNext = onSaveOption;
  } else {
    fnExecNext = onSaveText;
  }

  return (
    <Box sx={{ my: 1 }}>
      <Body1 textAlign="center">{ask.pergunta}</Body1>
      {typeAsk === "MEU" && ask?.opcoes?.length > 0 && (
        <OptionsAskForm
          options={ask.opcoes}
          setNextAsk={setNextAsk}
          setResponses={setResponses}
          askId={ask.id}
          questionId={questionId}
          response={response}
          onSaveOption={(e) => setValue(e.target.value)}
        />
      )}
      {typeAsk !== "MEU" && ask?.opcoes?.length === 0 && (
        <Stack>
          {typeAsk === "DATA" ? (
            <TextField
              label="Data"
              type="date"
              variant="outlined"
              fullWidth
              defaultValue={response}
              disabled={response ? true : false}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
          ) : (
            <ResponseAskText
              response={response}
              value={value}
              setValue={setValue}
            />
          )}
        </Stack>
      )}
      {observation && (
        <Body1
          textAlign="center"
          sx={{
            mt: 1,
            borderRadius: "4px",
            backgroundColor: "yellow",
            color: "black",
          }}
        >
          {observation}
        </Body1>
      )}
      {!response && (
        <>
          <Stack sx={{ my: 2 }} direction="row" justifyContent="space-between">
            <Button
              startIcon={<Icone icone="ArrowBack" />}
              variant="outlined"
              onClick={onGoBackAsk}
              disabled={disabledReturn}
            >
              ANTERIOR
            </Button>

            <Button
              startIcon={<Icone icone="ArrowForward" />}
              variant="contained"
              disabled={value === "" ? true : false}
              onClick={fnExecNext}
            >
              PRÓXIMA
            </Button>
          </Stack>
        </>
      )}
    </Box>
  );
};
//
const getOptionSelected = (options, value) => {
  return options.find((option) => option.id === parseInt(value));
};
//
const OptionsAskForm = ({
  options,
  askId,
  setNextAsk,
  setResponses,
  questionId,
  response,
  onSaveOption,
}) => {
  const itens = options.map((option) => [option.id, option.texto]);

  return (
    <Stack alignItems="center">
      <RadioForm
        defaultValue={response}
        itens={itens}
        disabled={response ? true : false}
        orientation="horizontal"
        onChange={response ? null : onSaveOption}
      />
    </Stack>
  );
};
//
const ResponseAskText = ({ response, value, setValue }) => {
  return (
    <TextField
      fullWidth
      multiline
      disabled={response ? true : false}
      defaultValue={response || value}
      onChange={(e) => setValue(e.target.value)}
      rows={4}
      variant="outlined"
      placeholder="Digite sua resposta..."
    />
  );
};
export default QuestionForm;
