import React, { useCallback, useEffect, useState } from "react";
import { Icone, Calendario } from "../../../../components";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { prizeDrawEscalonateCalendar } from "../escalonada-actions";
import { useToggle } from "react-use";
import { selectPrizeDrawEscalonateCalendar } from "../escalonada-selectors";
import {
  Avatar,
  AvatarGroup,
  ButtonBase,
  Stack,
  Grow,
  Container,
  Button,
  useTheme,
  Chip,
} from "@mui/material";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import EscalonadaCalendarioLista from "./escalonada-calendario-lista";
import EscalonadaCalendarioTitulo from "./escalonada-calendario-titulo";
import EscalonadaCalendarioVerDia from "./escalonada-calendario-ver-dia";
import { onDownload } from "../escalonada-utils";

const ROTAS = ["escalonada_calendario_gerar_excel_api"];

const STR = {
  titleTotalEscalonatesToday: "Total de escalonadas no dia",
  labelBtnDownloadInExcel: "Baixar em Excel",
};

function EscalonadaCalendario() {
  const isMobile = useTheme()?.isMobile;
  const date = format(new Date(), "yyyy-MM-dd");
  const [wait, setWait] = useToggle();
  const dispatch = useDispatch();
  //
  useEffect(() => {
    dispatch(
      prizeDrawEscalonateCalendar(
        {
          mes: date.split("-")[1],
          ano: date.split("-")[0],
        },
        setWait
      )
    );
  }, [dispatch, setWait, date]);

  return isMobile ? (
    <CorpoMobile dateInit={date} wait={wait} />
  ) : (
    <CorpoDesktop dateInit={date} wait={wait} />
  );
}

const CorpoMobile = ({ dateInit, wait }) => {
  const history = useHistory();

  const onSelectedDay = useCallback(
    (escalonates, day) => {
      history.push(EscalonadaCalendarioVerDia.rota, {
        escalonates,
        escalonateDay: day,
      });
    },
    [history]
  );

  return (
    <EscalonadaCalendarioVer
      onSelectedDay={onSelectedDay}
      dateInit={dateInit}
      wait={wait}
    />
  );
};

const CorpoDesktop = ({ dateInit, wait }) => {
  const [day, setDay] = useState(null);
  const escalonateCalendar = useSelector(selectPrizeDrawEscalonateCalendar);

  return (
    <Stack sx={{ mt: 1 }} direction="row">
      <Grow
        in={!Boolean(day)}
        timeout={{ enter: 200, exit: 100 }}
        unmountOnExit
      >
        <Container maxWidth={false}>
          <EscalonadaCalendarioVer
            onSelectedDay={(_, inDay) => setDay(inDay)}
            dateInit={dateInit}
            wait={wait}
          />
        </Container>
      </Grow>
      <Grow in={Boolean(day)} timeout={{ enter: 200, exit: 100 }} unmountOnExit>
        <Container maxWidth={false}>
          <Stack>
            <Stack direction="row" justifyContent="space-between">
              <Button
                variant="contained"
                startIcon={<Icone icone="ArrowBack" />}
                onClick={() => setDay(null)}
              >
                Voltar
              </Button>
              <EscalonadaCalendarioTitulo day={day} />
              <div />
            </Stack>
            {escalonateCalendar && day && (
              <EscalonadaCalendarioLista
                escalonateDay={day}
                escalonates={escalonateCalendar[day]}
              />
            )}
          </Stack>
        </Container>
      </Grow>
    </Stack>
  );
};

const EscalonadaCalendarioVer = ({ wait, dateInit, onSelectedDay }) => {
  const isMobile = useTheme()?.isMobile;
  const [isWait, setWait] = useToggle(wait);
  const dispatch = useDispatch();

  const escalonateCalendar = useSelector(selectPrizeDrawEscalonateCalendar);
  //
  const renderEscalonate = useCallback(
    (day) => {
      if (escalonateCalendar && escalonateCalendar.hasOwnProperty(day)) {
        const escalonates = escalonateCalendar[day];
        const responses = _.range(0, escalonates.length).map(() => false);
        escalonates.forEach((escalonate, idx) => {
          responses[idx] = escalonate.escalonada.questionario_respondido;
        });

        const allResponses = responses.every((response) => response);

        return (
          <ButtonBase
            title={STR.titleTotalEscalonatesToday}
            sx={{
              width: "100%",
              height: "56px",
              background:
                responses?.length > 0 ? (allResponses ? "green" : "red") : null,
            }}
            onClick={() => onSelectedDay(escalonates, day)}
          >
            {isMobile ? (
              <Chip
                icon={<Icone icone="Timeline" />}
                label={escalonates.length}
              />
            ) : (
              <AvatarGroup max={4}>
                {escalonates.map((escalonate, idx) => {
                  return escalonate?.auditor_nome ? (
                    <Avatar
                      title={escalonate.auditor_nome}
                      sx={{ width: 32, height: 32 }}
                      alt={escalonate.auditor_nome}
                      src={escalonate.auditor_avatar}
                      key={escalonate.id}
                    />
                  ) : null;
                })}
              </AvatarGroup>
            )}
          </ButtonBase>
        );
      }
      return null;
    },
    [onSelectedDay, isMobile, escalonateCalendar]
  );
  //
  const onChangeCalendar = useCallback(
    (dateFrom) => {
      dispatch(
        prizeDrawEscalonateCalendar(
          {
            mes: dateFrom.split("-")[1],
            ano: dateFrom.split("-")[0],
          },
          setWait
        )
      );
    },
    [dispatch, setWait]
  );
  return (
    <Container maxWidth="lg">
      <Stack direction="row-reverse">
        <Button
          startIcon={<Icone icone="Download" />}
          variant="contained"
          onClick={() =>
            onDownload(
              ROTAS[0],
              {
                ano: dateInit.split("-")[0],
                mes: dateInit.split("-")[1],
              },
              setWait
            )
          }
        >
          {STR.labelBtnDownloadInExcel}
        </Button>
      </Stack>
      <Calendario
        onControleCalendario={onChangeCalendar}
        aguardar={isWait}
        dataInicial={dateInit}
        render={renderEscalonate}
      />
    </Container>
  );
};

export default EscalonadaCalendario;
