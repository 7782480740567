import React, { useEffect } from "react";
import BackgroundRouter from "../../background-router";
import { Container } from "@mui/material";
import {
  AnimacaoSemDados,
  Body1,
  FormDeAte,
  H6,
  Pesquisando,
  TabelaV2,
} from "../../../components";
import { useFetch } from "../../../hooks";
import { optTabela, ToastErro } from "../../../utils/utils";

const STR = {
  title: "Relatório de Férias",
  subtitle:
    "Defina o periodo, somente retorna os dados de férias de quem não fez a assinatura das férias no App",
  waitQuery: "Aguardando você consultar os dados",
};

const RelatorioFerias = () => {
  const { data, wait, setFetch, error } = useFetch(
    "/relatorio_ferias_api",
    "POST",
    "JSON"
  );

  useEffect(() => {
    if (error) ToastErro(error);
  }, [error]);

  return (
    <BackgroundRouter>
      <Container maxWidth={false}>
        <H6>{STR.title}</H6>
        <Body1 align="center">{STR.subtitle}</Body1>
        <br />
        <Container maxWidth="md">
          <FormDeAte aguardar={wait} onSubmit={setFetch} />
        </Container>
        {wait ? (
          <Pesquisando />
        ) : data ? (
          <RenderTable {...data} />
        ) : (
          <AnimacaoSemDados titulo={STR.waitQuery} />
        )}
      </Container>
    </BackgroundRouter>
  );
};

const RenderTable = ({ cabe, corpo }) => {
  const optTabelaT = {
    ...optTabela,
    data: [3, 4, 5, 6, 7, 8],
  };

  return <TabelaV2 cabe={cabe} corpo={corpo} {...optTabelaT} />;
};

RelatorioFerias.rota = "/relatorio_ferias";

export default RelatorioFerias;
