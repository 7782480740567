import React, { useCallback, useEffect } from "react";
import * as yup from "yup";
import _ from "lodash";
import { EntradaForm, H6, Pesquisando } from "../../../components";
import { Stack } from "@mui/material";
import { useToggle } from "react-use";
import { useDispatch, useSelector } from "react-redux";
import { VALIDADOR_TIPO, obterValidador } from "../../../utils/validadores";
import {
  selectApply,
  selectCourses,
  selectDevelop,
  selectEfficiency,
  selectInstructors,
  selectTypeOfCourses,
  selectStudents,
} from "./treinamento-selectors";
import { format } from "date-fns";
import { trainningAdd, trainningEdit } from "./treinamento-actions";
import { useFetch } from "../../../hooks";
import { ToastErro } from "../../../utils/utils";
import { selectIsMat, selectIsPlant } from "../../../utils/global-selectors";
import { useTranslation } from "react-i18next";

const KEYS = {
  id_course: "id_curso",
  instructor: "instrutor",
  date_init: "data_inicio",
  local: "local",
  id_type: "id_tipo",
  id_efficiency: "id_eficacia",
  duration: "duracao",
  theme: "temas",
  trainning_apply: "treinamento_aplicavel",
  trainning_development: "treinamento_desenvolvimento",
  observation: "observacao",
  document: "documentos",
  more_efficiency: "mais_eficacia",
  more_trainning: "mais_treinamento",
  approved: "aprovacao",
  finish: "ecerramento",
  plant: "planta",
  responsibility: "responsavel",
  date_realized: "data_realizacao",
  presence: "presenca",
  certificate: "certificado",
};

const getYerOrNo = () => {
  return [
    ["S", "SIM"],
    ["N", "NÃO"],
  ];
};

const formatGeneral = (data, idI) => {
  let dataDefault = _.filter(data, (val) => val[0] === idI);
  if (dataDefault.length === 0) return null;
  return {
    value: dataDefault[0][0],
    label: dataDefault[0][1],
    key: dataDefault[0][0],
  };
};

function TreinamentoFormAdd({ treinamento }) {
  const dispatch = useDispatch();
  const [wait, setWait] = useToggle();
  const courses = useSelector(selectCourses);
  const instructors = useSelector(selectInstructors);
  const typeOfCourses = useSelector(selectTypeOfCourses);
  const efficiency = useSelector(selectEfficiency);
  const develop = useSelector(selectDevelop);
  const apply = useSelector(selectApply);
  const plantDefault = useSelector(selectIsPlant);
  const matDefault = useSelector(selectIsMat);
  const responsibilitys = useSelector(selectStudents);
  const { t } = useTranslation();

  let courseDefault,
    instructorDefault,
    dateInitDefault,
    durationDefault,
    localDefault,
    typeOfCourseDefault,
    efficiencyDefault,
    developmentDefault,
    applyDefault,
    observationDefault,
    documentDefault,
    moreEfficiencyDefault,
    moreTrainningDefault,
    themeDefault,
    approvedDefault,
    finishDefault,
    dateRealizedDefault,
    presenceDefault,
    certificateDefault,
    responsibilityDefault;
  responsibilityDefault = formatGeneral(
    responsibilitys,
    `${plantDefault}_${matDefault}`
  );
  //
  if (treinamento) {
    courseDefault = formatGeneral(courses, treinamento.curso);
    instructorDefault = formatGeneral(instructors, treinamento.instrutor.id);
    dateInitDefault = treinamento.data_treinamento;
    durationDefault = treinamento.duracao;
    localDefault = treinamento.local;
    typeOfCourseDefault = formatGeneral(typeOfCourses, treinamento.id_tipo);
    efficiencyDefault = formatGeneral(efficiency, treinamento.id_eficacia);
    developmentDefault = formatGeneral(
      develop,
      treinamento.treinamento_desenvolvimento
    );
    applyDefault = formatGeneral(apply, treinamento.treinamento_aplicavel);
    observationDefault = treinamento.observacao;
    documentDefault = treinamento.documentos;
    moreEfficiencyDefault = treinamento.mais_eficacia;
    moreTrainningDefault = treinamento.mais_treinamento;
    themeDefault = treinamento.temas;
    approvedDefault = treinamento.aprovacao;
    finishDefault = treinamento.ecerramento;
    presenceDefault = treinamento.presenca;
    certificateDefault = treinamento.certificado;
    responsibilityDefault = formatGeneral(
      responsibilitys,
      `${treinamento.planta}_${treinamento.mat}`
    );
    dateRealizedDefault = treinamento.data_realizacao;
  }

  const schema = [
    {
      type: "select",
      label: t("treinamento.labelCourse"),
      name: KEYS.id_course,
      itens: courses,
      defaultValue: courseDefault,
      disabled: treinamento ? true : false,
      autoFormat: true,
      placeholder: t("treinamento.placeholderCourse"),
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      type: "select",
      label: t("treinamento.labelInstructor"),
      name: KEYS.instructor,
      itens: instructors,
      autoFormat: true,
      disabled: treinamento ? true : false,
      defaultValue: instructorDefault,
      placeholder: t("treinamento.placeholderInstructor"),
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      type: "select",
      label: t("treinamento.labelResponsibility"),
      name: KEYS.responsibility,
      itens: responsibilitys,
      autoFormat: true,
      disabled: treinamento ? true : false,
      defaultValue: responsibilityDefault,
      placeholder: t("treinamento.placeholderResponsibility"),
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      type: "date",
      label: t("treinamento.labelDateRealized"),
      name: KEYS.date_realized,
      disabled: treinamento ? true : false,
      defaultValue: dateRealizedDefault,
      placeholder: t("treinamento.placeholderDateRealized"),
      extraProps: {
        inputProps: {
          min: format(new Date(), "yyyy-MM-dd"),
        },
      },
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      type: "date",
      label: t("treinamento.labelDateInit"),
      name: KEYS.date_init,
      placeholder: t("treinamento.placeholderDateInit"),
      defaultValue: dateInitDefault,
      disabled: treinamento ? true : false,
      extraProps: {
        inputProps: {
          min: format(new Date(), "yyyy-MM-dd"),
        },
      },
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      type: "time",
      label: t("treinamento.labelDuration"),
      name: KEYS.duration,
      placeholder: t("treinamento.placeholderDuration"),
      defaultValue: durationDefault,
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      type: "textarea",
      label: t("treinamento.labelLocal"),
      name: KEYS.local,
      defaultValue: localDefault,
      disabled: treinamento ? true : false,
      minRows: 3,
      multiline: true,
      placeholder: t("treinamento.placeholderLocal"),
    },
    {
      type: "select",
      label: t("treinamento.labelTypeOfCourse"),
      name: KEYS.id_type,
      itens: typeOfCourses,
      autoFormat: true,
      defaultValue: typeOfCourseDefault,
      placeholder: t("treinamento.placeholderTypeOfCourse"),
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      type: "select",
      label: t("treinamento.labelEfficacy"),
      name: KEYS.id_efficiency,
      itens: efficiency,
      autoFormat: true,
      defaultValue: efficiencyDefault,
      placeholder: t("treinamento.placeholderEfficacy"),
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      type: "select",
      label: t("treinamento.labelDevelopment"),
      name: KEYS.trainning_development,
      itens: develop,
      autoFormat: true,
      defaultValue: developmentDefault,
      placeholder: t("treinamento.placeholderDevelopment"),
    },
    {
      type: "select",
      label: t("treinamento.labelApply"),
      name: KEYS.trainning_apply,
      itens: apply,
      autoFormat: true,
      defaultValue: applyDefault,
      placeholder: t("treinamento.placeholderApply"),
    },

    {
      type: "textarea",
      label: t("treinamento.labelObservation"),
      name: KEYS.observation,
      minRows: 2,
      multiline: true,
      defaultValue: observationDefault,
      placeholder: t("treinamento.placeholderObservation"),
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      type: "textarea",
      label: t("treinamento.labelDocument"),
      name: KEYS.document,
      minRows: 2,
      multiline: true,
      defaultValue: documentDefault,
      placeholder: t("treinamento.placeholderDocument"),
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      type: "radio",
      orientation: "horizontal",
      label: t("treinamento.labelMoreTimeEfficiency"),
      name: KEYS.more_efficiency,
      itens: getYerOrNo(),
      defaultValue: moreEfficiencyDefault,
      placeholder: t("treinamento.placeholderMoreTimeEfficiency"),
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      type: "radio",
      orientation: "horizontal",
      label: t("treinamento.labelMoreTrainning"),
      name: KEYS.more_trainning,
      itens: getYerOrNo(),
      defaultValue: moreTrainningDefault,
      placeholder: t("treinamento.placeholderMoreTrainning"),
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      type: "radio",
      orientation: "horizontal",
      label: t("treinamento.labelCertificate"),
      name: KEYS.certificate,
      itens: getYerOrNo(),
      defaultValue: certificateDefault,
      placeholder: t("treinamento.placeholderCertificate"),
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      type: "radio",
      orientation: "horizontal",
      label: t("treinamento.labelPresence"),
      name: KEYS.presence,
      itens: getYerOrNo(),
      defaultValue: presenceDefault,
      placeholder: t("treinamento.placeholderPresence"),
      grid: {
        xs: 12,
        md: 6,
      },
    },
    {
      type: "textarea",
      label: t("treinamento.labelTheme"),
      name: KEYS.theme,
      minRows: 2,
      multiline: true,
      defaultValue: themeDefault,
      placeholder: t("treinamento.placeholderTheme"),
    },
    {
      type: "textarea",
      label: t("treinamento.labelApproved"),
      name: KEYS.approved,
      minRows: 2,
      multiline: true,
      defaultValue: approvedDefault,
      placeholder: t("treinamento.placeholderApproved"),
    },
    {
      type: "textarea",
      label: t("treinamento.labelFinish"),
      name: KEYS.finish,
      minRows: 2,
      multiline: true,
      defaultValue: finishDefault,
      placeholder: t("treinamento.placeholderFinish"),
    },
  ];
  const schemaValidator = yup.object().shape({
    [KEYS.id_course]: obterValidador(VALIDADOR_TIPO.selectUnico),
    [KEYS.instructor]: obterValidador(VALIDADOR_TIPO.selectUnico),
    [KEYS.date_init]: obterValidador(VALIDADOR_TIPO.data),
    [KEYS.local]: obterValidador(VALIDADOR_TIPO.texto, 2),
    [KEYS.responsibility]: obterValidador(VALIDADOR_TIPO.selectUnico),
  });
  const schemaMessageError = {
    [KEYS.id_course]: t("treinamento.errorCourse"),
    [KEYS.instructor]: t("treinamento.errorInstructor"),
    [KEYS.date_init]: t("treinamento.errorDateInit"),
    [KEYS.local]: t("treinamento.errorLocal"),
    [KEYS.responsibility]: t("treinamento.errorResponsibility"),
  };

  const onSubmit = useCallback(
    (val) => {
      const obj = {
        [KEYS.id_course]: parseInt(val[KEYS.id_course].value),
        [KEYS.instructor]: val[KEYS.instructor].value,
        [KEYS.date_init]: format(val[KEYS.date_init], "yyyy-MM-dd"),
        [KEYS.local]: val[KEYS.local],
        [KEYS.id_type]: val[KEYS.id_type]
          ? parseInt(val[KEYS.id_type].value)
          : null,
        [KEYS.id_efficiency]: val[KEYS.id_efficiency]
          ? parseInt(val[KEYS.id_efficiency].value)
          : null,
        [KEYS.duration]: val[KEYS.duration] || null,
        [KEYS.theme]: val[KEYS.theme] || null,
        [KEYS.trainning_apply]: val[KEYS.trainning_apply]
          ? parseInt(val[KEYS.trainning_apply].value)
          : null,
        [KEYS.trainning_development]: val[KEYS.trainning_development]
          ? parseInt(val[KEYS.trainning_development].value)
          : null,
        [KEYS.observation]: val[KEYS.observation] || null,
        [KEYS.document]: val[KEYS.document] || null,
        [KEYS.more_efficiency]: val[KEYS.more_efficiency] || null,
        [KEYS.more_trainning]: val[KEYS.more_trainning] || null,
        [KEYS.approved]: val[KEYS.approved] || null,
        [KEYS.finish]: val[KEYS.finish] || null,
        [KEYS.certificate]: val[KEYS.certificate] || null,
        [KEYS.presence]: val[KEYS.presence] || null,
        [KEYS.responsibility]: val[KEYS.responsibility]
          ? val[KEYS.responsibility].value
          : null,
        [KEYS.date_realized]: val[KEYS.date_init]
          ? format(val[KEYS.date_init], "yyyy-MM-dd")
          : null,
      };

      if (treinamento) {
        // Remover campos que não podem ser alterados
        delete obj[KEYS.id_course];
        delete obj[KEYS.instructor];
        delete obj[KEYS.date_init];
        delete obj[KEYS.local];

        obj.id_treinamento = treinamento.id;

        dispatch(trainningEdit(obj, setWait));
      } else {
        obj[KEYS.plant] = plantDefault;
        dispatch(trainningAdd(obj, setWait));
      }
    },
    [dispatch, plantDefault, treinamento, setWait]
  );

  return (
    <Stack>
      <H6>{t("treinamento.titleFormAddNewCourse")}</H6>
      <EntradaForm
        schema={schema}
        schemaValidator={schemaValidator}
        schemaMessageError={schemaMessageError}
        onSubmit={onSubmit}
        wait={wait}
      />
    </Stack>
  );
}

export const TreinamentoWrapper = ({ id }) => {
  const { wait, setFetch, error, data } = useFetch("/treinamento_ver", "GET");
  useEffect(() => {
    if (id) {
      setFetch({ id });
    }
  }, [id, setFetch]);

  //
  useEffect(() => {
    if (error) ToastErro(error);
  }, [error]);

  return wait ? (
    <Pesquisando />
  ) : data ? (
    <TreinamentoFormAdd treinamento={data.treinamento_id} />
  ) : null;
};

export default TreinamentoFormAdd;
