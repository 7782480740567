import { yupResolver } from "@hookform/resolvers/yup";
import { Button, CircularProgress, Stack, TextField } from "@mui/material";
import { format, subDays } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Icone from "./icone";
import * as yup from "yup";

//
const schema = yup.object().shape({
  de: yup.date().required(),
  ate: yup.date().required(),
});

// Componente que executa a consulta dos dados
const FormDeAte = ({ aguardar, onSubmit }) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });
  // Funcao para receber os dados para a consulta
  const fn = (val) => {
    const { de, ate } = val;
    onSubmit({
      de: format(de, "yyyy-MM-dd"),
      ate: format(ate, "yyyy-MM-dd"),
    });
  };

  return (
    <Stack
      spacing={1}
      direction={{ xs: "column", md: "row" }}
      alignItems="center"
      justifyContent="center"
    >
      <Controller
        name="de"
        control={control}
        defaultValue={format(subDays(new Date(), 1), "yyyy-MM-dd")}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            type="date"
            {...field}
            fullWidth
            size="small"
            label={t("absenteismo.labelDateFrom")}
            disabled={aguardar}
            InputLabelProps={{ shrink: true }}
            error={!!errors.de}
            helperText={!!errors.de && t("absenteismo.errorDateFormat")}
          />
        )}
      />
      <Controller
        control={control}
        name="ate"
        defaultValue={format(subDays(new Date(), 1), "yyyy-MM-dd")}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            type="date"
            {...field}
            fullWidth
            disabled={aguardar}
            size="small"
            label={t("absenteismo.labelDateTo")}
            InputLabelProps={{ shrink: true }}
            error={!!errors.ate}
            helperText={!!errors.ate && t("absenteismo.errorDateFormat")}
          />
        )}
      />
      <Button
        onClick={handleSubmit(fn)}
        fullWidth
        variant="contained"
        disabled={aguardar}
        startIcon={
          aguardar ? <CircularProgress size={20} /> : <Icone icone="Search" />
        }
      >
        {t("absenteismo.labelBtnSearch")}
      </Button>
    </Stack>
  );
};

export default FormDeAte;
